import { http } from "src/config";
import { FUNCDateToString, FUNCNumberFormat } from "src/config/function";
import { URLSVC } from "src/config/helper/urlService";
import Swal from "sweetalert2";
import { loadingBar, setDataPermohonans } from "../globalAction";

export const setDataUploadBayar = (idPermohonan) => {
  return (dispatch) => {
    loadingBar(true);
    dispatch({ type: "RESET_FORM_UPLOAD_BAYAR" });
    http
      .get(
        `${URLSVC}/Pelayanans/GetPermohonanById?IdPermohonan=${idPermohonan}`
      )
      .then((res) => {
        loadingBar(false);
        let data = res.data;
        if (data.IsSuccess) {
          dispatch({ type: "MODAL_UPLOAD_BAYAR", payload: true });
          dispatch(setFormUploadBayar("IdPermohonan", data.Data.IdPermohonan));
          dispatch(setFormUploadBayar("NoPermohonan", data.Data.NoPermohonan));
          dispatch(setFormUploadBayar("Nama", data.Data.Nama));
          dispatch(setFormUploadBayar("Email", data.Data.Email));
        } else {
          Swal.fire("Gagal", data.ReturnMessage, "error");
        }
      });
  };
};

export const setFormUploadBayar = (formType, formValue) => {
  return { type: "FORM_UPLOAD_BAYAR", formType, formValue };
};

export const apiUploadBuktiBayar = (iData) => {
  return (dispatch) => {
    loadingBar(true);
    var fd = new FormData();

    fd.append("IdPermohonan", iData.IdPermohonan);
    fd.append("TanggalBayar", FUNCDateToString(iData.TanggalBayar));
    fd.append("RpBayar", FUNCNumberFormat(`${iData.RpBayar}`));
    fd.append("BuktiBayar", iData.BuktiBayar);

    http
      .post(`${URLSVC}/Pelayanans/UploadBuktiBayarFromWeb`, fd)
      .then((res) => {
        loadingBar(false);
        let data = res.data;
        if (data.IsSuccess) {
          Swal.fire(
            "Berhasil",
            `Anda telah berhasil mengupload bukti pembayaran pemohon`,
            "success"
          ).then(function () {
            dispatch({ type: "MODAL_UPLOAD_BAYAR", payload: false });
            dispatch(setDataPermohonans("DATA_UPLOAD_BAYARS"));
          });
        } else {
          Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
        }
      });
  };
};

///VERIFIKASI

export const setDataVerifikasiBayar = (idPermohonan) => {
  return (dispatch) => {
    loadingBar(true);
    dispatch({ type: "RESET_FORM_VERIFIKASI_BAYAR" });
    http
      .get(
        `${URLSVC}/Pelayanans/GetPermohonanById?IdPermohonan=${idPermohonan}`
      )
      .then((res) => {
        loadingBar(false);
        let data = res.data;
        if (data.IsSuccess) {
          dispatch({ type: "MODAL_VERIFIKASI_BAYAR", payload: true });
          dispatch(
            setFormVerifikasiBayar("IdPermohonan", data.Data.IdPermohonan)
          );
          dispatch(
            setFormVerifikasiBayar("NoPermohonan", data.Data.NoPermohonan)
          );
          dispatch(setFormVerifikasiBayar("Nama", data.Data.Nama));
          dispatch(setFormVerifikasiBayar("Email", data.Data.Email));

          let buktiBayar = "";
          data.Data.Files.map((v, i) => {
            if (v.IdFile === 99) {
              buktiBayar = `${v.PathUrl}/${v.NamaFile}`;
            }
          });
          dispatch(setFormVerifikasiBayar("BuktiBayar", buktiBayar));
        } else {
          Swal.fire("Gagal", data.ReturnMessage, "error");
        }
      });
  };
};
export const setFormVerifikasiBayar = (formType, formValue) => {
  return { type: "FORM_VERIFIKASI_BAYAR", formType, formValue };
};
export const apiVerifikasiPembayaran = (iData) => {
  return (dispatch) => {
    loadingBar(true);
    var fd = new FormData();

    fd.append("IdPermohonan", iData.IdPermohonan);
    fd.append("StatusProses", iData.StatusProses.value);
    fd.append("Catatan", iData.Catatan);

    http.post(`${URLSVC}/Pelayanans/VerifikasiPembayaran`, fd).then((res) => {
      loadingBar(false);
      let data = res.data;
      if (data.IsSuccess) {
        Swal.fire(
          "Berhasil",
          `Anda telah berhasil memperbarui status pembayaran`,
          "success"
        ).then(function () {
          dispatch({
            type: "MODAL_VERIFIKASI_BAYAR",
            payload: false,
          });
          dispatch(setDataPermohonans("DATA_VERIFIKASI_BAYARS"));
        });
      } else {
        Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
      }
    });
  };
};
