const initialState = {
  dataPerpanjangMemberSbs: [],
  formPerpanjangMemberSb: {
    FileDownloads: [],
    Catatan: "",
    JumlahBayar: 0,
  },
  modalPerpanjangMemberSb: false,
};

const perpanjangMemberSbReducer = (state = initialState, action) => {
  switch (action.type) {
    case "DATA_PERPANJANG_MEMBER_SBS":
      return { ...state, dataPerpanjangMemberSbs: action.payload };
    case "FORM_PERPANJANG_MEMBER_SB":
      return {
        ...state,
        formPerpanjangMemberSb: {
          ...state.formPerpanjangMemberSb,
          [action.formType]: action.formValue,
        },
      };
    case "RESET_FORM_PERPANJANG_MEMBER_SB":
      return {
        ...state,
        formPerpanjangMemberSb: initialState.formPerpanjangMemberSb,
      };
    case "MODAL_PERPANJANG_MEMBER_SB":
      return { ...state, modalPerpanjangMemberSb: action.payload };
    default:
      return state;
  }
};

export default perpanjangMemberSbReducer;
