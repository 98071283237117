const initialState = {
  dataComboProv: [],
  dataComboKab: [],
  dataComboKec: [],
  dataComboDesa: [],
  dataComboJenisBantuan: [],
  dataComboJenisSurveyor: [],
  dataComboRole: [],

  dataComboKelPermohonan: [],
  dataComboJenisPermohonan: [],
  dataComboSyaratPermohonan: [],
  dataComboSyaratPermohonan1: [],
  dataComboKjsb: [],
  dataComboSurveyor: [],
  dataComboJenisProperti: [],
};

const comboReducer = (state = initialState, action) => {
  switch (action.type) {
    case "DATA_COMBO_PROV":
      return { ...state, dataComboProv: action.payload };
    case "DATA_COMBO_KAB":
      return { ...state, dataComboKab: action.payload };
    case "DATA_COMBO_KEC":
      return { ...state, dataComboKec: action.payload };
    case "DATA_COMBO_DESA":
      return { ...state, dataComboDesa: action.payload };
    case "DATA_COMBO_JENIS_BANTUAN":
      return { ...state, dataComboJenisBantuan: action.payload };
    case "DATA_COMBO_JENIS_SURVEYOR":
      return { ...state, dataComboJenisSurveyor: action.payload };
    case "DATA_COMBO_ROLE":
      return { ...state, dataComboRole: action.payload };
    case "DATA_COMBO_KEL_PERMOHONAN":
      return { ...state, dataComboKelPermohonan: action.payload };
    case "DATA_COMBO_JENIS_PERMOHONAN":
      return { ...state, dataComboJenisPermohonan: action.payload };
    case "DATA_COMBO_SYARAT_PERMOHONAN":
      return { ...state, dataComboSyaratPermohonan: action.payload };
    case "DATA_COMBO_SYARAT_PERMOHONAN_1":
      return { ...state, dataComboSyaratPermohonan1: action.payload };

    case "DATA_COMBO_KJSB":
      return { ...state, dataComboKjsb: action.payload };
    case "DATA_COMBO_SURVEYOR":
      return { ...state, dataComboSurveyor: action.payload };
    case "DATA_COMBO_JENIS_PROPERTI":
      return { ...state, dataComboJenisProperti: action.payload };

    default:
      return state;
  }
};

export default comboReducer;
