const initialState = {
    dataIklans: [],
    dataIklan: null,
    modalIklan: false,
    formIklan: {
        IdIklan:'',
        Judul:'',
        TipeKontak:'',
        Kontak:'',
        MinTanggalTayang: new Date(),
        MaxTanggalTayang: new Date(),
        FileUpload:'',
        Status: '',
        
        IsTipeKontak: 0,
    },
    fileImageIklanPreview: '',
}

const iklanReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'DATA_IKLANS':
            return { ...state, dataIklans: action.payload }
        case 'DATA_IKLAN':
            return { ...state, dataIklan: action.payload }
        case 'FORM_IKLAN':
            return {
                ...state,
                formIklan: {
                    ...state.formIklan,
                    [action.formType]: action.formValue
                }
            }
        case 'RESET_FORM_IKLAN':
            return { ...state, formIklan: initialState.formIklan }
        case 'MODAL_IKLAN':
            return { ...state, modalIklan: action.payload }
        case 'FILE_IMAGE_IKLAN_PREVIEW':
            return { ...state, fileImageIklanPreview: action.payload }
        default:
            return state
    }
}

export default iklanReducer;