import React, { useEffect, useState } from "react";
import {
  CCard,
  CModal,
  CModalHeader,
  CModalBody,
  CTooltip,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { DataTable } from "src/components";
import { useDispatch, useSelector } from "react-redux";
import {
  setDataPermohonans,
  setDataPermohonan,
  apiDeletePermohonanFromWeb,
} from "src/config/redux/action";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";

const Permohonan = () => {
  const history = useHistory();
  const {
    dataPermohonans,
    formPermohonan,
    modalDownloadPermohonan,
    berkasPermohonanSyarats,
    modalPelayananPreviewBerkas,
  } = useSelector((state) => state.permohonanReducer);
  const dispatch = useDispatch();

  const {
    NoPermohonan,
    IdJenisPermohonan,
    IdKjsb,
    IdProv,
    Nama,
    NoKtp,
    Alamat,
    NoHp,
    Email,
    AlamatBidangTanah,
    LuasTanah,
    JarakLokasiBidangTanah,
    JumlahBidangSebelum,
    JumlahBidangSetelah,
    IdPermohonan,
    Latitude,
    Longitude,
  } = formPermohonan;

  const { setValue } = useForm();

  useEffect(() => {
    setValue("IdPermohonan", IdPermohonan);
    setValue("IdJenisPermohonan", IdJenisPermohonan);
    setValue("IdKjsb", IdKjsb);
    setValue("Nama", Nama);
    setValue("NoKtp", NoKtp);
    setValue("Alamat", Alamat);
    setValue("NoHp", NoHp);
    setValue("Email", Email);
    setValue("AlamatBidangTanah", AlamatBidangTanah);
    setValue("LuasTanah", LuasTanah);
    setValue("JarakLokasiBidangTanah", JarakLokasiBidangTanah);
    setValue("JumlahBidangSebelum", JumlahBidangSebelum);
    setValue("JumlahBidangSetelah", JumlahBidangSetelah);
    setValue("Files", "");
    setValue("Latitude", Latitude);
    setValue("Longitude", Longitude);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    IdPermohonan,
    IdJenisPermohonan,
    IdKjsb,
    IdProv,
    Nama,
    NoKtp,
    Alamat,
    NoHp,
    Email,
    AlamatBidangTanah,
    LuasTanah,
    JarakLokasiBidangTanah,
    JumlahBidangSebelum,
    JumlahBidangSetelah,
    Latitude,
    Longitude,
  ]);

  useEffect(() => {
    dispatch(setDataPermohonans("DATA_PERMOHONANS", 1));
  }, [dispatch]);

  const btnDelete = (idPermohonan, nama) =>
    dispatch(apiDeletePermohonanFromWeb(idPermohonan, nama));

  const showDownloadModal = () =>
    dispatch({
      type: "MODAL_DOWNLOAD_PERMOHONAN",
      payload: !modalDownloadPermohonan,
    });

  const btnPreview = (idPermohonan) => {
    dispatch(setDataPermohonan(idPermohonan));
    dispatch({
      type: "MODAL_PELAYANAN_PREVIEW_BERKAS",
      payload: !modalPelayananPreviewBerkas,
    });
  };

  const columns = [
    {
      title: "AKSI",
      field: "internal_action",
      cellStyle: {
        width: 220,
        minWidth: 220,
      },
      headerStyle: {
        width: 220,
        minWidth: 220,
      },
      render: (rowData) => (
        <>
          <div style={{}}>
            <CTooltip content="Perbarui Data" placement="right">
              <button
                type="button"
                className="btn btn-sm btn-info"
                onClick={() =>
                  history.push(`/permohonan/edit/${rowData.IdPermohonan}`)
                }
              >
                <CIcon name="cil-pencil" />
              </button>
            </CTooltip>
            <CTooltip content="Validasi Berkas" placement="right">
              <button
                type="button"
                className="btn btn-sm btn-success ml-1"
                onClick={() =>
                  history.push(`/permohonan/validasi/${rowData.IdPermohonan}`)
                }
              >
                <CIcon name="cil-check" />
              </button>
            </CTooltip>
            <CTooltip content="Hapus" placement="right">
              <button
                type="button"
                className="btn btn-sm btn-danger ml-1"
                onClick={() =>
                  btnDelete(rowData.IdPermohonan, `${rowData.Nama}`)
                }
              >
                <CIcon name="cil-trash" />
              </button>
            </CTooltip>
            <CTooltip content="Lihat" placement="right">
              <button
                type="button"
                className="btn btn-sm btn-dark ml-1"
                onClick={() => btnPreview(rowData.IdPermohonan)}
              >
                <CIcon name="cil-search" />
              </button>
            </CTooltip>
          </div>
        </>
      ),
    },
    {
      title: "NO.PEMOHON",
      field: "NoPermohonan",
      cellStyle: {
        width: 120,
        minWidth: 120,
      },
      headerStyle: {
        width: 120,
        minWidth: 120,
      },
      render: (rowData) => <code>{rowData.NoPermohonan}</code>,
    },
    {
      title: "PEMOHON",
      field: "Nama",
      cellStyle: {
        width: 200,
        minWidth: 200,
      },
      headerStyle: {
        width: 200,
        minWidth: 200,
      },
    },
    {
      title: "STATUS",
      field: "Status",
      cellStyle: {
        width: 180,
        minWidth: 180,
      },
      headerStyle: {
        width: 180,
        minWidth: 180,
      },
      render: (rowData) => (
        <span className="badge badge-info">{rowData.Status}</span>
      ),
    },
    {
      title: "STATUS PROSES",
      field: "StatusProses",
      cellStyle: {
        width: 220,
        minWidth: 220,
      },
      headerStyle: {
        width: 220,
        minWidth: 220,
      },
      render: (rowData) => (
        <>
          <span className="badge badge-warning">
            {rowData.StatusProses || "-"}
          </span>
        </>
      ),
    },
    {
      title: "NO.KTP",
      field: "NoKtp",
    },
    {
      title: "KJSB",
      field: "Kjsb",
      cellStyle: {
        width: 200,
        minWidth: 200,
      },
      headerStyle: {
        width: 200,
        minWidth: 200,
      },
    },
    {
      title: "ALAMAT",
      field: "Alamat",
      cellStyle: {
        width: 230,
        minWidth: 230,
      },
      headerStyle: {
        width: 230,
        minWidth: 230,
      },
      render: (rowData) => (
        <>
          <span
            style={{
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              width: "300px",
              display: "block",
              overflow: "hidden",
            }}
          >
            {rowData.Alamat}
          </span>
        </>
      ),
    },
    {
      title: "NO.HP",
      field: "NoHp",
    },
    {
      title: "EMAIL",
      field: "Email",
    },
    {
      title: "ALAMAT BIDANG TANAH",
      field: "AlamatBidangTanah",
      cellStyle: {
        width: 250,
        minWidth: 250,
      },
      headerStyle: {
        width: 250,
        minWidth: 250,
      },
      render: (rowData) => (
        <>
          <span
            style={{
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              width: "300px",
              display: "block",
              overflow: "hidden",
            }}
          >
            {rowData.Alamat}
          </span>
        </>
      ),
    },
    {
      title: "LUAS TANAH",
      field: "LuasTanah",
      cellStyle: {
        width: 150,
        minWidth: 150,
      },
      headerStyle: {
        width: 150,
        minWidth: 150,
      },
    },
    {
      title: "JARAK LOKASI BIDANG TANAH",
      field: "JarakLokasiBidangTanah",
      cellStyle: {
        width: 310,
        minWidth: 310,
      },
      headerStyle: {
        width: 310,
        minWidth: 310,
      },
    },
  ];

  return (
    <div className="mb-4">
      <button
        type="button"
        className="btn btn-sm btn-success mb-4"
        onClick={() => history.push("/permohonan/add")}
      >
        <CIcon name="cil-user" /> TAMBAH PERMOHONAN BARU
      </button>
      <CCard>
        <DataTable
          columns={columns}
          data={dataPermohonans}
          title={<span style={{ color: "#3C4B64" }}>DATA PERMOHONAN</span>}
        />
      </CCard>

      <CModal show={modalDownloadPermohonan} onClose={showDownloadModal}>
        <CModalHeader closeButton>DOWNLOAD BERKAS</CModalHeader>
        <CModalBody>
          <div className="row">
            <div className="col-md-12">
              <form id="form">
                <div className="form-group">
                  <label>NO. PERMOHONAN</label>
                  <input
                    readOnly
                    type="text"
                    className="form-control"
                    value={NoPermohonan}
                  />
                </div>
                <div className="form-group">
                  <label>NAMA</label>
                  <input
                    readOnly
                    type="text"
                    className="form-control"
                    value={Nama}
                  />
                </div>
                <hr />
                {berkasPermohonanSyarats.map((v, i) => {
                  return v.IdFile !== 99 ? (
                    <div className="form-group" key={i}>
                      <a
                        target="blank"
                        href={`${v.PathUrl}/${v.NamaFile}`}
                        className="btn btn-primary btn-block"
                      >
                        <b>[DOWNLOAD]</b> - {v.JenisFile}{" "}
                      </a>
                    </div>
                  ) : null;
                })}
              </form>
            </div>
          </div>
        </CModalBody>
      </CModal>
    </div>
  );
};

export default Permohonan;
