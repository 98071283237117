import { http } from "src/config";
import { FUNCDateToString, FUNCNumberFormat } from "src/config/function";
import { URLSVC } from "src/config/helper/urlService";
import Swal from "sweetalert2";
import { loadingBar, setDataPermohonans } from "../globalAction";

export const setDataHasilAkhir = (idPermohonan) => {
  return (dispatch) => {
    loadingBar(true);
    dispatch({ type: "RESET_FORM_HASIL_AKHIR" });
    http
      .get(`${URLSVC}/Webs/GetPermohonanById?IdPermohonan=${idPermohonan}`)
      .then((res) => {
        loadingBar(false);
        let data = res.data;
        if (data.IsSuccess) {
          dispatch(setFormHasilAkhir("IdPermohonan", data.Data.IdPermohonan));
          dispatch(setFormHasilAkhir("NoPermohonan", data.Data.NoPermohonan));
          dispatch(setFormHasilAkhir("Nama", data.Data.Nama));
          dispatch(setFormHasilAkhir("Email", data.Data.Email));
          dispatch(
            setFormHasilAkhir("JenisPermohonan", data.Data.JenisPermohonan.Nama)
          );
          dispatch(
            setFormHasilAkhir("AlamatBidangTanah", data.Data.AlamatBidangTanah)
          );
          dispatch(
            setFormHasilAkhir(
              "JarakLokasiBidangTanah",
              data.Data.JarakLokasiBidangTanah
            )
          );
          dispatch(setFormHasilAkhir("LuasTanah", data.Data.LuasTanah));
          dispatch(setFormHasilAkhir("Latitude", data.Data.Latitude));
          dispatch(setFormHasilAkhir("Longitude", data.Data.Longitude));
          dispatch(setFormHasilAkhir("Wilker", data.Data.Wilker));
          dispatch(setFormHasilAkhir("Kjsb", data.Data.Kjsb.Nama));
          dispatch(
            setFormHasilAkhir(
              "JadwalUkur",
              data.Data.JadwalUkur && new Date(data.Data.JadwalUkur)
            )
          );

          dispatch(
            setDataPermohonanOutputHasilAkhirs(data.Data.JenisPermohonan.Id)
          );
        } else {
          Swal.fire("Gagal", data.ReturnMessage, "error");
        }
      });
  };
};

export const setDataPermohonanOutputHasilAkhirs = (idJenisPermohonan) => {
  return (dispatch) => {
    loadingBar(true);
    http
      .get(
        `${URLSVC}/Pelayanans/GetPermohonanOutputHasilAkhirs?IdJenisPermohonan=${idJenisPermohonan}`
      )
      .then((res) => {
        loadingBar(false);
        let data = res.data;
        if (data.IsSuccess) {
          dispatch({ type: "FILE_SYARAT_HASIL_AKHIR", payload: data.Data });
        } else {
          Swal.fire("Gagal", data.ReturnMessage, "error");
        }
      });
  };
};

export const setFormHasilAkhir = (formType, formValue) => {
  return { type: "FORM_HASIL_AKHIR", formType, formValue };
};

export const apiInputHasilAkhirx = (iData, history) => {
  return (dispatch) => {
    loadingBar(true);
    var fd = new FormData();

    fd.append("IdPermohonan", iData.IdPermohonan);

    if (iData.Files !== "") {
      iData.Files.map((v, i) => {
        if (typeof v.IdFile !== "undefined") {
          fd.append(`Files[${i}].IdFile`, v.IdFile);
          fd.append(`Files[${i}].FileUpload`, v.FileUpload);
        }
      });
    }

    http.post(`${URLSVC}/Pelayanans/InputHasilAkhir`, fd).then((res) => {
      loadingBar(false);
      let data = res.data;
      if (data.IsSuccess) {
        Swal.fire(
          "Berhasil",
          `Anda telah berhasil simpan data`,
          "success"
        ).then(function () {
          history.goBack();
        });
      } else {
        Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
      }
    });
  };
};
