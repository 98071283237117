import React, { useEffect, useState } from "react";
import {
  CCard,
  CModal,
  CModalHeader,
  CModalBody,
  CModalFooter,
  CButton,
  CRow,
  CCol,
  CFormGroup,
  CLabel,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";

import {
  DataTable,
  ReactDatePicker,
  ReactSelect,
  Upload,
} from "src/components";
import { useDispatch, useSelector } from "react-redux";
import {
  setDataBanners,
  setDataBanner,
  setImgPreview,
  apiAddBanner,
  apiUpdateBanner,
  apiDeleteBanner,
} from "src/config/redux/action";
import {
  FUNCValidateUploadFileExtension,
  FUNCValidateUploadFileSize,
} from "src/config";
import { useForm, Controller } from "react-hook-form";
import { IMGNoPhoto } from "src/assets";

function DataBannerList() {
  const { dataBanners, formBanner, fileImageBannerPreview, modalBanner } =
    useSelector((state) => state.bannerReducer);
  const {
    IdMobileBanner,
    Description,
    MinTanggalTayang,
    MaxTanggalTayang,
    FileUpload,
    Status,
  } = formBanner;
  const [isEdit, setIsEdit] = useState(false);
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    getValues,
    reset,
  } = useForm();

  useEffect(() => {
    setValue("IdMobileBanner", IdMobileBanner);
    setValue("Description", Description);
    setValue("MinTanggalTayang", MinTanggalTayang);
    setValue("MaxTanggalTayang", MaxTanggalTayang);
    setValue("FileUpload", FileUpload);
    setValue("Status", Status);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    IdMobileBanner,
    Description,
    MinTanggalTayang,
    MaxTanggalTayang,
    FileUpload,
    Status,
  ]);

  useEffect(() => {
    dispatch(setDataBanners());
  }, [dispatch]);

  const handleEdit = (isAction, idBanner) => {
    reset();

    var idFile = document.getElementById("fileImage");
    idFile.value = idFile.defaultValue;

    if (isAction === "add") {
      setIsEdit(false);
      showModal();
      dispatch({ type: "RESET_FORM_BANNER" });
    }
    if (isAction === "edit") {
      setIsEdit(true);
      dispatch(setDataBanner(idBanner));
    }
  };

  const btnAddBanner = (data) => dispatch(apiAddBanner(data));

  const btnEditBanner = (data) => dispatch(apiUpdateBanner(data));

  const showModal = () =>
    dispatch({ type: "MODAL_BANNER", payload: !modalBanner });

  const onImgBanner = (e) => {
    FUNCValidateUploadFileSize(e.target, 2048, "2MB");
    FUNCValidateUploadFileExtension(e.target);
    const file = e.target.files[0];

    if (file !== undefined) {
      setValue("FileUpload", file);
      dispatch(
        setImgPreview("FILE_IMAGE_BANNER_PREVIEW", URL.createObjectURL(file))
      );
    } else {
      setValue("FileUpload", "");
      dispatch(
        setImgPreview("FILE_IMAGE_BANNER_PREVIEW", fileImageBannerPreview)
      );
    }
  };

  const columns = [
    {
      title: "AKSI",
      field: "internal_action",
      cellStyle: {
        width: 270,
        minWidth: 270,
      },
      headerStyle: {
        width: 270,
        minWidth: 270,
      },
      render: (rowData) => (
        <>
          <div style={{}}>
            <button
              type="button"
              className="btn btn-sm btn-primary mr-1"
              onClick={() => handleEdit("edit", rowData.IdMobileBanner)}
            >
              <CIcon name="cil-pencil" /> PERBARUI
            </button>
            <button
              type="button"
              className="btn btn-sm btn-danger"
              onClick={() => dispatch(apiDeleteBanner(rowData.IdMobileBanner))}
            >
              <CIcon name="cil-trash" /> HAPUS
            </button>
          </div>
        </>
      ),
    },
    {
      title: "BANNER",
      field: "ImageBanner",
      render: (rowData) => (
        <img
          src={rowData.ImageBanner}
          style={{
            width: 50,
            height: 50,
            objectFit: "contain",
            borderRadius: 5,
          }}
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = IMGNoPhoto;
          }}
        />
      ),
    },
    {
      title: "DESKRIPSI",
      field: "Description",
    },
    {
      title: "TGL. TAYANG MULAI/AKHIR",
      field: "MinTanggalTayang",
      render: (rowData) => (
        <>
          <span className="badge badge-success">
            {rowData.MinTanggalTayang}
          </span>{" "}
          /{" "}
          <span className="badge badge-danger">{rowData.MaxTanggalTayang}</span>
        </>
      ),
    },
    {
      title: "STATUS",
      field: "Status",
      render: (rowData) =>
        rowData.Status.Id === 1 ? (
          <span className="badge badge-info">AKTIF</span>
        ) : (
          <span className="badge badge-danger">TIDAK AKTIF</span>
        ),
    },
  ];

  const dataJsonStatus = [
    {
      value: "1",
      label: "Aktif",
    },
    {
      value: "-1",
      label: "Tidak Akif",
    },
  ];
  return (
    <div>
      <button
        type="button"
        className="btn btn-sm btn-success mb-4"
        onClick={() => handleEdit("add")}
      >
        <CIcon name="cil-user" /> TAMBAH BANNER BARU
      </button>
      <div className="mb-4">
        <CCard>
          <DataTable
            columns={columns}
            data={dataBanners}
            title={<span style={{ color: "#3C4B64" }}>DATA BANNER</span>}
            options={{
              paging: true,
              headerStyle: { position: "sticky", top: 0 },
              overflowX: "auto",
            }}
          />
        </CCard>
      </div>

      <CModal show={modalBanner} onClose={showModal}>
        <CModalHeader closeButton>
          {isEdit ? `PERBARUI DATA` : `TAMBAH BARU`}
        </CModalHeader>
        <form onSubmit={handleSubmit(isEdit ? btnEditBanner : btnAddBanner)}>
          <CModalBody>
            <CRow>
              <CCol md="12">
                <input
                  hidden
                  defaultValue={IdMobileBanner}
                  name="IdMobileBanner"
                  className="form-control"
                  {...register("IdMobileBanner")}
                />
                <CFormGroup>
                  <CLabel>Deskripsi</CLabel>
                  <input
                    defaultValue={Description}
                    name="Description"
                    className="form-control"
                    placeholder="Ketikan Deskripsi..."
                    {...register("Description", {
                      required: "Kolom wajib diisi",
                    })}
                  />
                  {errors.Description && (
                    <span className="text-danger">
                      {errors.Description?.message}
                    </span>
                  )}
                </CFormGroup>
                <CFormGroup>
                  <CLabel>Tgl. Mulai Tayang </CLabel>
                  <Controller
                    control={control}
                    defaultValue={MinTanggalTayang}
                    name="MinTanggalTayang"
                    render={({ field }) => (
                      <ReactDatePicker
                        placeholderText="Ketikan Tgl. Mulai Tayang..."
                        selected={field.value}
                        value={getValues("MinTanggalTayang")}
                        onChange={(val) => field.onChange(val)}
                        dateFormat="dd-MM-yyyy"
                      />
                    )}
                    rules={{
                      required: true,
                    }}
                  />
                  {errors.MinTanggalTayang && (
                    <span className="text-danger">Kolom wajib diisi</span>
                  )}
                </CFormGroup>
                <CFormGroup>
                  <CLabel>Tgl. Akhir Tayang</CLabel>
                  <Controller
                    control={control}
                    defaultValue={MaxTanggalTayang}
                    name="MaxTanggalTayang"
                    render={({ field }) => (
                      <ReactDatePicker
                        placeholderText="Ketikan Tgl. Akhir Tayang..."
                        selected={field.value}
                        value={getValues("MaxTanggalTayang")}
                        onChange={(val) => field.onChange(val)}
                        dateFormat="dd-MM-yyyy"
                      />
                    )}
                    rules={{
                      required: true,
                    }}
                  />
                  {errors.MaxTanggalTayang && (
                    <span className="text-danger">Kolom wajib diisi</span>
                  )}
                </CFormGroup>
                <CFormGroup>
                  <label>Status </label>
                  <Controller
                    control={control}
                    defaultValue={Status}
                    name="Status"
                    render={({ field, ref }) => (
                      <ReactSelect
                        inputRef={ref}
                        classNamePrefix="addl-class"
                        options={dataJsonStatus}
                        value={getValues("Status")}
                        onChange={(val) => {
                          field.onChange(val);
                        }}
                        isClearable={true}
                      />
                    )}
                    rules={{
                      required: true,
                    }}
                  />
                  {errors.Status && (
                    <span className="text-danger">Kolom wajib diisi</span>
                  )}
                </CFormGroup>
                <div className="alert alert-primary">
                  <b>Info Upload File</b> <br />
                  1. Ukuran file maksimal 2MB <br />
                  2. Format file yang digunakan wajib .pdf .png .jpg
                </div>
                <CFormGroup>
                  <label>Banner </label>
                  <Upload
                    id="fileImage"
                    onChange={(e) => onImgBanner(e)}
                    img={fileImageBannerPreview}
                  />
                </CFormGroup>
              </CCol>
            </CRow>
          </CModalBody>
          <CModalFooter>
            <CButton color="primary" type="submit">
              {isEdit ? "PERBARUI DATA" : "SIMPAN DATA"}
            </CButton>{" "}
            <CButton color="secondary" onClick={showModal}>
              BATAL
            </CButton>
          </CModalFooter>
        </form>
      </CModal>
    </div>
  );
}

export default DataBannerList;
