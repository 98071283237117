const initialState = {
    dataKorwilDataSbs: [],
    formKorwilDataSb: false,
    modalKorwilDataSb: false,
    formJenisBantuan: {
        Id: '',
        Keterangan: ''
    }
}

const korwilDataSbReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'DATA_KORWIL_DATA_SBS':
            return { ...state, dataKorwilDataSbs: action.payload }
        case 'FORM_KORWIL_DATA_SBS':
            return {
                ...state,
                formKorwilDataSb: {
                    ...state.formKorwilDataSb,
                    [action.formType]: action.formValue
                }
            }
        case 'RESET_FORM_KORWIL_DATA_SBS':
            return { ...state, formKorwilDataSb: initialState.formKorwilDataSb }
        case 'MODAL_KORWIL_DATA_SB':
            return { ...state, modalKorwilDataSb: action.payload }
        default:
            return state
    }
}

export default korwilDataSbReducer;