import { CSpinner } from "@coreui/react";
import React from "react";
const ProgressBar = (
  <div
    id="loading"
    className="bg-primary"
    style={{
      display: "block",
      position: "absolute",
      width: "100%",
      height: "100%",
    }}
  >
    <div
      style={{
        height: "100%",
        display: "flex",
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <center>
        <CSpinner color="white" style={{ width: "4rem", height: "4rem" }} />
      </center>
    </div>
  </div>
);
export default ProgressBar;
