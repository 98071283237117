const initialState = {
    dataProvinsis: [],
    modalProvinsi: false,
    formProvinsi: {
        Kode: '',
        Nama: '',
        EmailKorwil: ''
    },
}

const wilayahAdministrasiReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'DATA_PROVINSIS':
            return { ...state, dataProvinsis: action.payload }
        case 'FORM_PROVINSI':
            return {
                ...state,
                formProvinsi: {
                    ...state.formProvinsi,
                    [action.formType]: action.formValue
                }
            }
        case 'RESET_FORM_PROVINSI':
            return { ...state, formProvinsi: initialState.formProvinsi }
        case 'MODAL_PROVINSI':
            return { ...state, modalProvinsi: action.payload }
        default:
            return state
    }
}

export default wilayahAdministrasiReducer;