const initialState = {
  dataSurveyors: [],
  dataSurveyorNoKjsbs: [],
  dataRegSkbs: [],
  dataRegSkb: false,
  modalSurveyorNoKjsb: false,
  modalSurveyor: false,
  modalSurveyorBerkas: false,
  modalRegSurveyorData: false,
  modalRegSurveyorPay: false,
  modalRegSurveyorBerkas: false,
  formSurveyor: {
    IdSurveyor: "",
    FirstName: "",
    MiddleName: "",
    LastName: "",
    Address: "",
    IdDesa: "",
    PhoneNumber: "",
    MobileNumber: "",
    Email: "",
    IdJenisSurveyor: "",
    NoLisensi: "",
    NoKtp: "",
    IdWilker: "",
    Files: [],
    SelectProv: "",
    SelectKab: "",
    SelectKec: "",
    SelectDesa: "",
    TanggalLisensi: "",
    IdPilihWilkerKjsb: { value: 1, label: "WILAYAH KERJA" },
    IsIdPilihWilkerKjsb: 1,
    IdKjsb: {
      label: null,
      value: null,
    },
    IdRegSkb: "",
    NoRegSkb: "",
    Catatan: "",
    BuktiBayar: "ok",
    FileDownloads: [],
    KartuAnggota: "",
  },
  dataCountRegSkb: {
    Registrasi: 0,
    MenungguPembayaran: 0,
    UploadBuktiBayar: 0,
    Terdaftar: 0,
  },
  filePasFotoSurveyorPreview: "",
  fileKtpSurveyorPreview: "",
  fileLisensiSurveyorPreview: "",

  fileSurveyor: [],
  serkomFile: "",
};

const surveyorReducer = (state = initialState, action) => {
  switch (action.type) {
    case "DATA_SURVEYORS":
      return { ...state, dataSurveyors: action.payload };
    case "DATA_SURVEYOR_NO_KJSBS":
      return { ...state, dataSurveyorNoKjsbs: action.payload };
    case "DATA_REG_SKBS":
      return { ...state, dataRegSkbs: action.payload };
    case "DATA_REG_SKB":
      return { ...state, dataRegSkb: action.payload };
    case "DATA_COUNT_REG_SKB":
      return { ...state, dataCountRegSkb: action.payload };
    case "FORM_SURVEYOR":
      return {
        ...state,
        formSurveyor: {
          ...state.formSurveyor,
          [action.formType]: action.formValue,
        },
      };
    case "RESET_FORM_SURVEYOR":
      return { ...state, formSurveyor: initialState.formSurveyor };
    case "MODAL_SURVEYOR":
      return { ...state, modalSurveyor: action.payload };
    case "MODAL_SURVEYOR_BERKAS":
      return { ...state, modalSurveyorBerkas: action.payload };
    case "MODAL_SURVEYOR_NO_KJSB":
      return { ...state, modalSurveyorNoKjsb: action.payload };
    case "MODAL_REG_SURVEYOR_DATA":
      return { ...state, modalRegSurveyorData: action.payload };
    case "MODAL_REG_SURVEYOR_PAY":
      return { ...state, modalRegSurveyorPay: action.payload };
    case "MODAL_REG_SURVEYOR_BERKAS":
      return { ...state, modalRegSurveyorBerkas: action.payload };
    case "FILE_PAS_FOTO_SURVEYOR_PREVIEW":
      return { ...state, filePasFotoSurveyorPreview: action.payload };
    case "FILE_KTP_SURVEYOR_PREVIEW":
      return { ...state, fileKtpSurveyorPreview: action.payload };
    case "FILE_LISENSI_SURVEYOR_PREVIEW":
      return { ...state, fileLisensiSurveyorPreview: action.payload };
    case "FILE_SURVEYOR":
      return { ...state, fileSurveyor: action.payload };
    case "SERKOM_FILE":
      return { ...state, serkomFile: action.payload };
    default:
      return state;
  }
};

export default surveyorReducer;
