const initialState = {
  dataPengukurans: [],
  modalPengukuran: false,
  formPengukuran: {
    IdPermohonan: "",
    NoPermohonan: "",
    Nama: "",
    Email: "",
    JenisPermohonan: "",
    AlamatBidangTanah: "",
    JarakLokasiBidangTanah: "",
    LuasTanah: "",
    Latitude: "",
    Longitude: "",
    Wilker: {},
    Kjsb: "",
  },
  fileSyaratPengukuran: [],
};

const pengukuranReducer = (state = initialState, action) => {
  switch (action.type) {
    case "DATA_PENGUKURANS":
      return { ...state, dataPengukurans: action.payload };
    case "FORM_PENGUKURAN":
      return {
        ...state,
        formPengukuran: {
          ...state.formPengukuran,
          [action.formType]: action.formValue,
        },
      };
    case "RESET_FORM_PENGUKURAN":
      return {
        ...state,
        formPengukuran: initialState.formPengukuran,
      };
    case "FILE_SYARAT_PENGUKURAN":
      return { ...state, fileSyaratPengukuran: action.payload };
    default:
      return state;
  }
};

export default pengukuranReducer;
