import React, { useEffect, useState } from "react";
import {
  CCard,
  CButton,
  CRow,
  CCol,
  CWidgetDropdown,
  CWidgetProgress,
  CProgress,
  CCardHeader,
  CCardBody,
  CCallout,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import ChartBarSimple from "src/views/template/charts/ChartBarSimple";
import { CChart, CChartDoughnut } from "@coreui/react-chartjs";
import { useDispatch, useSelector } from "react-redux";
import {
  setDataPermohonanByNo,
  setDataUserInfoSession,
} from "src/config/redux/action";
import { FUNCSetFullName } from "src/config";

const line = {
  labels: ["January", "February", "March", "April", "May", "June", "July"],
  datasets: [
    {
      label: "Total Permohonan KJSB",
      fill: false,
      lineTension: 0.1,
      backgroundColor: "#FF6B6B",
      borderColor: "#FF6B6B",
      borderCapStyle: "butt",
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: "miter",
      pointBorderColor: "#FF6B6B",
      pointBackgroundColor: "#fff",
      pointBorderWidth: 1,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: "#FF6B6B",
      pointHoverBorderColor: "rgba(220,220,220,1)",
      pointHoverBorderWidth: 2,
      pointRadius: 1,
      pointHitRadius: 10,
      data: [65, 59, 80, 81, 56, 55, 40],
    },
    {
      label: "Total Permohonan Tugas Saya",
      fill: false,
      lineTension: 0.1,
      backgroundColor: "#5B9A8B",
      borderColor: "#5B9A8B",
      borderCapStyle: "butt",
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: "miter",
      pointBorderColor: "#5B9A8B",
      pointBackgroundColor: "#fff",
      pointBorderWidth: 1,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: "#5B9A8B",
      pointHoverBorderColor: "rgba(220,220,220,1)",
      pointHoverBorderWidth: 2,
      pointRadius: 1,
      pointHitRadius: 10,
      data: [35, 29, 10, 1, 5, 15, 20],
    },
  ],
};

const Home = () => {
  const dataUserSession = useSelector(
    (state) => state.homeReducer.dataUserSession
  );

  const [noPermohonan, setNoPermohonan] = useState(null);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setDataUserInfoSession());
  }, [dispatch]);

  const btnPreview = () => dispatch(setDataPermohonanByNo(noPermohonan, true));

  return (
    <div>
      <CRow>
        <CCol lg="12" className="mb-2">
          <CCallout color="primary" className={"bg-white"}>
            <div className="p-2">
              <div className="text-muted mb-1">
                Hai, Selamat datang di web portal SURLIS (Surveyor Berlisensi)
              </div>
              <strong className="h4 text-capitalize">
                {FUNCSetFullName(
                  dataUserSession?.FirstName,
                  dataUserSession?.MiddleName,
                  dataUserSession?.LastName
                ).toLowerCase()}
              </strong>
            </div>
          </CCallout>
        </CCol>
        <CCol sm="6" lg="7">
          <CRow>
            <CCol sm="6" lg="6">
              <CWidgetDropdown
                color="primary"
                header="9.823"
                text="Total Pelayanan (KJSB)"
                footerSlot={
                  <ChartBarSimple
                    className="mt-3 mx-3"
                    style={{ height: "55px" }}
                    backgroundColor="rgb(250, 152, 152)"
                    label="Permohonan"
                    labels="months"
                  />
                }
              />
            </CCol>
            <CCol sm="6" lg="6">
              <CWidgetProgress
                header="11"
                text="Tugas Saya (SB)"
                footer="Tugas ukur yang harus dikerjakan tersisa"
              >
                <CProgress
                  color="primary"
                  animated
                  size="xs"
                  className="my-3"
                  value={75}
                />
              </CWidgetProgress>
            </CCol>
            <CCol sm="6" lg="12">
              <CCard>
                <CCardHeader>Cari Berkas Permohonan</CCardHeader>
                <CCardBody>
                  <CRow>
                    <CCol lg="10">
                      <input
                        name="NoPermohonan"
                        className="form-control"
                        placeholder="Ketikan Nomor Permohonan..."
                        onChange={(e) => setNoPermohonan(e.target.value)}
                      />
                    </CCol>
                    <CCol lg="2">
                      <CButton
                        color="primary"
                        type="button"
                        block
                        onClick={() => btnPreview()}
                      >
                        <CIcon name="cil-search" /> Cari
                      </CButton>
                    </CCol>
                  </CRow>
                </CCardBody>
              </CCard>
            </CCol>
          </CRow>
        </CCol>
        <CCol sm="6" lg="5">
          <CCard>
            <CCardHeader>Chart Progress</CCardHeader>
            <CCardBody>
              <CChartDoughnut
                datasets={[
                  {
                    backgroundColor: ["#fc2947", "#FF7A75"],
                    data: [40, 20],
                  },
                ]}
                labels={["Selesai", "Dalam Proses"]}
                options={{
                  tooltips: {
                    enabled: true,
                  },
                }}
              />
            </CCardBody>
          </CCard>
        </CCol>
        <CCol lg="12">
          <CCard>
            <CCardHeader>Statik Total Pelayanan (Bulan)</CCardHeader>
            <CCardBody>
              <CChart
                type="line"
                datasets={line.datasets}
                options={{
                  legend: { display: false },
                  layout: { padding: 20, height: 300 },
                  maintainAspectRatio: false,
                }}
                labels="months"
                style={{ height: 400 }}
              />
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </div>
  );
};

export default Home;
