import React from 'react';
import { IMGNoPhoto } from '../../../assets';

const Upload = ({img, ...rest}) => {
    return (
        <div>
            { img && <img
                style={{ width: 100, height:100, objectFit:'contain', backgroundColor:'grey', borderRadius:5 }}
                src={img} alt="preview"
                onError={(e) => { e.target.onerror = null; e.target.src = IMGNoPhoto }}
            />}
            <input
                type="file"
                className="form-control mt-2" {...rest}
            />
        </div>
    )
}

export default Upload
