const initialState = {
    dataKecamatans: [],
    modalKecamatan: false,
    formKecamatan: {
        IdKabupaten: '',
        Kode: '',
        Nama: ''
    },
}

const wilKecamatanReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'DATA_KECAMATANS':
            return { ...state, dataKecamatans: action.payload }
        case 'FORM_KECAMATAN':
            return {
                ...state,
                formKecamatan: {
                    ...state.formKecamatan,
                    [action.formType]: action.formValue
                }
            }
        case 'RESET_FORM_KECAMATAN':
            return { ...state, formKecamatan: initialState.formKecamatan }
        case 'MODAL_KECAMATAN':
            return { ...state, modalKecamatan: action.payload }
        default:
            return state
    }
}

export default wilKecamatanReducer;