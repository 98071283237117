import { http } from "src/config";
import { FUNCDateToString, FUNCNumberFormat } from "src/config/function";
import { URLSVC } from "src/config/helper/urlService";
import Swal from "sweetalert2";
import { loadingBar, setDataPermohonans } from "../globalAction";

export const setDataUploadBayarCekPlot = (idPermohonan) => {
  return (dispatch) => {
    loadingBar(true);
    dispatch({ type: "RESET_FORM_UPLOAD_BAYAR_CEK_PLOT" });
    http
      .get(
        `${URLSVC}/Pelayanans/GetPermohonanById?IdPermohonan=${idPermohonan}`
      )
      .then((res) => {
        loadingBar(false);
        let data = res.data;
        if (data.IsSuccess) {
          dispatch({ type: "MODAL_UPLOAD_BAYAR_CEK_PLOT", payload: true });
          dispatch(
            setFormUploadBayarCekPlot("IdPermohonan", data.Data.IdPermohonan)
          );
          dispatch(
            setFormUploadBayarCekPlot("NoPermohonan", data.Data.NoPermohonan)
          );
          dispatch(setFormUploadBayarCekPlot("Nama", data.Data.Nama));
          dispatch(setFormUploadBayarCekPlot("Email", data.Data.Email));
        } else {
          Swal.fire("Gagal", data.ReturnMessage, "error");
        }
      });
  };
};

export const setFormUploadBayarCekPlot = (formType, formValue) => {
  return { type: "FORM_UPLOAD_BAYAR_CEK_PLOT", formType, formValue };
};

export const apiUploadBuktiBayarCheckPlot = (iData) => {
  return (dispatch) => {
    loadingBar(true);
    var fd = new FormData();

    fd.append("IdPermohonan", iData.IdPermohonan);
    fd.append("TanggalBayar", FUNCDateToString(iData.TanggalBayar));
    fd.append("RpBayar", FUNCNumberFormat(`${iData.RpBayar}`));
    fd.append("BuktiBayar", iData.BuktiBayar);

    http
      .post(`${URLSVC}/Pelayanans/UploadBuktiBayarCheckplotFromWeb`, fd)
      .then((res) => {
        loadingBar(false);
        let data = res.data;
        if (data.IsSuccess) {
          Swal.fire(
            "Berhasil",
            `Anda telah berhasil mengupload bukti pembayaran pemohon`,
            "success"
          ).then(function () {
            dispatch({ type: "MODAL_UPLOAD_BAYAR_CEK_PLOT", payload: false });
            dispatch(setDataPermohonans("DATA_UPLOAD_BAYAR_CEK_PLOTS", 2));
          });
        } else {
          Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
        }
      });
  };
};

////VERIFIKASI

export const setDataVerifikasiBayarCekPlot = (idPermohonan) => {
  return (dispatch) => {
    loadingBar(true);
    dispatch({ type: "RESET_FORM_VERIFIKASI_BAYAR_CEK_PLOT" });
    http
      .get(
        `${URLSVC}/Pelayanans/GetPermohonanById?IdPermohonan=${idPermohonan}`
      )
      .then((res) => {
        loadingBar(false);
        let data = res.data;
        if (data.IsSuccess) {
          dispatch({ type: "MODAL_VERIFIKASI_BAYAR_CEK_PLOT", payload: true });
          dispatch(
            setFormVerifikasiBayarCekPlot(
              "IdPermohonan",
              data.Data.IdPermohonan
            )
          );
          dispatch(
            setFormVerifikasiBayarCekPlot(
              "NoPermohonan",
              data.Data.NoPermohonan
            )
          );
          dispatch(setFormVerifikasiBayarCekPlot("Nama", data.Data.Nama));
          dispatch(setFormVerifikasiBayarCekPlot("Email", data.Data.Email));
          dispatch(
            setFormVerifikasiBayarCekPlot(
              "BuktiBayar",
              `${data.Data.PemohonanBiayaCheckplot.PathUrl}/${data.Data.PemohonanBiayaCheckplot.BuktiBayar}`
            )
          );
        } else {
          Swal.fire("Gagal", data.ReturnMessage, "error");
        }
      });
  };
};

export const setFormVerifikasiBayarCekPlot = (formType, formValue) => {
  return { type: "FORM_VERIFIKASI_BAYAR_CEK_PLOT", formType, formValue };
};

export const apiVerifikasiBuktiBayarCheckPlot = (iData) => {
  return (dispatch) => {
    loadingBar(true);
    var fd = new FormData();

    fd.append("IdPermohonan", iData.IdPermohonan);
    fd.append("StatusProses", iData.StatusProses.value);
    fd.append("Catatan", iData.Catatan);

    http
      .post(`${URLSVC}/Pelayanans/VerifikasiPembayaranBiayaCheckPlot`, fd)
      .then((res) => {
        loadingBar(false);
        let data = res.data;
        if (data.IsSuccess) {
          Swal.fire(
            "Berhasil",
            `Anda telah berhasil memperbarui status pembayaran`,
            "success"
          ).then(function () {
            dispatch({
              type: "MODAL_VERIFIKASI_BAYAR_CEK_PLOT",
              payload: false,
            });
            dispatch(setDataPermohonans("DATA_VERIFIKASI_BAYAR_CEK_PLOTS", 3));
          });
        } else {
          Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
        }
      });
  };
};

////INPUTHASIL

export const setDataInputHasilCekPlot = (idPermohonan) => {
  return (dispatch) => {
    loadingBar(true);
    dispatch({ type: "RESET_FORM_INPUT_HASIL_CEK_PLOT" });
    http
      .get(
        `${URLSVC}/Pelayanans/GetPermohonanById?IdPermohonan=${idPermohonan}`
      )
      .then((res) => {
        loadingBar(false);
        let data = res.data;
        if (data.IsSuccess) {
          dispatch({ type: "MODAL_INPUT_HASIL_CEK_PLOT", payload: true });
          dispatch(
            setFormInputHasilCekPlot("IdPermohonan", data.Data.IdPermohonan)
          );
          dispatch(
            setFormInputHasilCekPlot("NoPermohonan", data.Data.NoPermohonan)
          );
          dispatch(setFormInputHasilCekPlot("Nama", data.Data.Nama));
          dispatch(setFormInputHasilCekPlot("Email", data.Data.Email));
        } else {
          Swal.fire("Gagal", data.ReturnMessage, "error");
        }
      });
  };
};

export const setFormInputHasilCekPlot = (formType, formValue) => {
  return { type: "FORM_INPUT_HASIL_CEK_PLOT", formType, formValue };
};

export const apiInputHasilCheckPlot = (iData) => {
  return (dispatch) => {
    loadingBar(true);
    var fd = new FormData();

    console.log(iData);

    fd.append("IdPermohonan", iData.IdPermohonan);
    fd.append("StatusProses", iData.StatusProses.value);
    fd.append("Catatan", iData.Catatan);
    fd.append("HasilCheckplot", iData.HasilCekPlot);

    http.post(`${URLSVC}/Pelayanans/InputHasilCheckPlot`, fd).then((res) => {
      loadingBar(false);
      let data = res.data;
      if (data.IsSuccess) {
        Swal.fire(
          "Berhasil",
          `Anda telah berhasil memperbarui status input hasil cek plot`,
          "success"
        ).then(function () {
          dispatch({
            type: "MODAL_INPUT_HASIL_CEK_PLOT",
            payload: false,
          });
          dispatch(setDataPermohonans("DATA_INPUT_HASIL_CEK_PLOTS"));
        });
      } else {
        Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
      }
    });
  };
};
