const initialState = {
    dataJenisData: [],
    dataPendudukJenisBantuan: [],
    dataUserSession: {}
}

const homeReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'DATA_JENIS_DATA':
            return { ...state, dataHasilSurveys: action.payload }
        case 'DATA_USER_SESSION':
            return { ...state, dataUserSession: action.payload }
        case 'DATA_PENDUDUK_JENIS_BANTUAN':
            return { ...state, dataPendudukJenisBantuan: action.payload }
        default:
            return state
    }
}

export default homeReducer;