const initialState = {
    dataUsers: [],
    dataUser: null,
    modalUser: false,
    formAddUser: {
        IdUser: '',
        Username: '',
        Password: '',
        Email: '',
        FirstName: '',
        MiddleName: '',
        LastName: '',
        Address: '',
        PhoneNumber: '',
        MobileNumber: '',
        FileImage: '',
        Roles: [],
    },
    fileImageUserPreview: '',
}

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'DATA_USERS':
            return { ...state, dataUsers: action.payload }
        case 'DATA_USER':
            return { ...state, dataUser: action.payload }
        case 'ADD_USER':
            return {
                ...state,
                formAddUser: {
                    ...state.formAddUser,
                    [action.formType]: action.formValue
                }
            }
        case 'RESET_FORM_ADD_USER':
            return { ...state, formAddUser: initialState.formAddUser }
        case 'MODAL_USER':
            return { ...state, modalUser: action.payload }
        case 'FILE_IMAGE_USER_PREVIEW':
            return { ...state, fileImageUserPreview: action.payload }
        default:
            return state
    }
}

export default userReducer;