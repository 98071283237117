import React from "react";
import { LayersControl, MapContainer, TileLayer, useMap } from "react-leaflet";

const ResizeMap = () => {
  const map = useMap();
  const resizeObserver = new ResizeObserver(() => {
    map.invalidateSize();
  });
  const container = document.getElementById("map-container");
  resizeObserver.observe(container);

  return null;
};

const LeafletPopup = ({ markerDraggable }) => {
  return (
    <MapContainer
      id="map-container"
      center={[2.25, 114.8333]}
      zoom={4}
      style={{ height: "50vh", width: "100%" }}
    >
      <LayersControl position="topright">
        <LayersControl.BaseLayer name="Google Maps" checked>
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">Google Maps</a> contributors'
            url="https://mt1.google.com/vt/lyrs=r&x={x}&y={y}&z={z}"
          />
        </LayersControl.BaseLayer>
        <LayersControl.BaseLayer name="World Imagery">
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">World Imagery</a> contributors'
            url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}.png"
          />
        </LayersControl.BaseLayer>
      </LayersControl>
      <ResizeMap />
      {markerDraggable}
    </MapContainer>
  );
};

export default LeafletPopup;
