import axios from "axios";
import { http, URLSVC } from "src/config";
import Swal from "sweetalert2";

export const setImgPreview = (type, payload) => {
  return { type, payload };
};

export const loadingBar = (bool = true) => {
  if (bool) {
    Swal.fire({
      title: "Sedang Memuat",
      html: "silahkan tunggu sebentar...",
      showConfirmButton: false,
      onBeforeOpen() {
        Swal.showLoading();
      },
      onAfterClose() {
        Swal.hideLoading();
      },
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
    });
  } else {
    Swal.close();
  }
};

export const setDataSyaratMemberUpdate = (id) => {
  return (dispatch) => {
    http
      .get(`${URLSVC}/Webs/GetSyaratMemberUpdate?IdMemberJenisUpdate=${id}`)
      .then((res) => {
        let data = res.data;
        if (data.IsSuccess) {
          dispatch({
            type: "DATA_SYARAT_MEMBER_UPDATE",
            payload: data.Data,
          });
        } else {
          Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
        }
      });
  };
};

export const setDataPermohonans = (type, status = "") => {
  loadingBar(true);
  return (dispatch) => {
    http
      .get(`${URLSVC}/Pelayanans/GetPermohonanAll?Status=${status}`)
      .then((res) => {
        loadingBar(false);
        let data = res.data;
        if (data.IsSuccess) {
          dispatch({ type, payload: data.Data });
        } else {
          // Swal.fire('Gagal', `${data.ReturnMessage}`, 'error');
        }
      });
  };
};

export const apiKalkulator = (iData) => {
  return (dispatch) => {
    loadingBar(true);
    axios
      .get(
        `${URLSVC}/Pelayanans/GetInfoPermohonanFromMobile?IdKabupaten=${iData.IdKabkot.value}&IdJenisPermohonan=${iData.IdPermohonan.value}&IdJenisProperty=${iData.IdProperti.value}&LuasTanahM2=${iData.luasTanah}`
      )
      .then((res) => {
        loadingBar(false);
        let data = res.data;
        if (data.IsSuccess) {
          dispatch({ type: "MODAL_HASIL_KALKULATOR", payload: true });
          dispatch({ type: "DATA_HASIL_KALKULATOR", payload: data.Data });
        } else {
          Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
        }
      });
  };
};

export const setDataSyaratPermohonans = (idJenisPermohonan) => {
  return (dispatch) => {
    http
      .get(
        `${URLSVC}/Pelayanans/GetSyaratPermohonans?IdJenisPermohonan=${idJenisPermohonan}`
      )
      .then((res) => {
        let data = res.data;
        if (data.IsSuccess) {
          dispatch({ type: "DATA_SYARAT_PERMOHONAN", payload: data.Data });
        } else {
          Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
        }
      });
  };
};

export const apiAddPermohonanByGuest = (iData) => {
  return (dispatch) => {
    loadingBar(true);
    var fd = new FormData();

    fd.append("IdJenisPermohonan", iData.IdJenisPermohonan.value);
    fd.append("IdKjsb", iData.IdKjsb.value);
    fd.append("IdSurveyor", iData.IdSurveyor?.value || "");
    fd.append("IdDesa", iData.IdDesa.value);
    fd.append("Nama", iData.Nama?.toUpperCase() || "");
    fd.append("NoHp", iData.NoHp || "000000");
    fd.append("Email", iData.Email || "byGuest@contoh.com");
    fd.append("AlamatBidangTanah", iData.AlamatBidangTanah || "byGuest");
    fd.append("LuasTanah", iData.LuasBidangTanah);
    fd.append("Latitude", iData.Latitude || "");
    fd.append("Longitude", iData.Longitude || "");
    fd.append("JumlahBidangSebelum", iData.JumlahBidangSebelum || "");
    fd.append("JumlahBidangSetelah", iData.JumlahBidangSetelah || "");

    fd.append("FileKtp", iData.FileKtp);

    http.post(`${URLSVC}/Pelayanans/AddPermohonanByGuest`, fd).then((res) => {
      loadingBar(false);
      let data = res.data;

      if (data.IsSuccess) {
        Swal.fire("Berhasil", `Permohonan berhasil diajukan!`, "success").then(
          function () {
            window.location.reload();
          }
        );
      } else {
        Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
      }
    });
  };
};
