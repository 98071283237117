import React from "react";
import { Route, Switch, HashRouter } from "react-router-dom";
import "./scss/style.scss";
import routes_guest from "src/routes/routes_guest";
import { ProgressBar } from "src/components";
import { NotFound, Home } from "src/views/guest";
import { TheFooter, TheHeaderGuest } from "./containers";

const TheLayout = React.lazy(() => import("./containers/TheLayout"));

const App = () => {
  return (
    <HashRouter>
      <React.Suspense fallback={ProgressBar}>
        <Switch>
          {routes_guest.map(({ path, name, Component, exact }, key) => {
            return (
              <Route
                key={key}
                exact={exact}
                path={path}
                name={name}
                render={(props) => (
                  <div className="c-wrapper">
                    {path !== "/login-petugas" && <TheHeaderGuest />}
                    <div className="min-vh-100">
                      <Component {...props} />
                    </div>
                    {path !== "/login-petugas" && (
                      <div className="absolute-bottom">
                        <TheFooter />
                      </div>
                    )}
                  </div>
                )}
              />
            );
          })}
          {localStorage.getItem("TOKEN") !== null ? (
            <Route
              path="/"
              name="Home"
              render={(props) => <TheLayout {...props} />}
            />
          ) : (
            <Route
              exact
              path="/"
              name="Landing Page"
              render={(props) => <Home {...props} />}
            />
          )}
          <Route render={(props) => <NotFound {...props} />} />
        </Switch>
      </React.Suspense>
    </HashRouter>
  );
};

export default App;
