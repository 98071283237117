import { http } from 'src/config';
import { URLSVC, URL } from 'src/config/helper/urlService';
import Swal from 'sweetalert2'
import { loadingBar, setImgPreview } from '../globalAction';


export const setDataUserKartuAnggota = () => {
    return (dispatch) => {
        http.get(`${URLSVC}/Webs/GetKartuAnggota`).then((res) => {
            loadingBar(false);
            let data = res.data;
            if (data.IsSuccess) {
                dispatch(setFormInfoUser('KartuAnggota', data.Data));
            } else {
                dispatch(setFormInfoUser('KartuAnggota', null));
            }
        })
    }
}

export const setFormInfoUser = (formType, formValue) => {
    return {type: 'FORM_INFO_USER', formType, formValue}
}

export const apiUpdateProfile = (iData) => {
    loadingBar(true);
    return (dispatch) => {
        var fd = new FormData();

        fd.append('Username', iData.Username);
        fd.append('Email', iData.Email);
        fd.append('FirstName', iData.FirstName);
        fd.append('MiddleName', iData.MiddleName === 'null' ? '' : iData.MiddleName);
        fd.append('LastName', iData.LastName === 'null' ? '' : iData.LastName);
        fd.append('Address', iData.Address);
        fd.append('PhoneNumber', iData.PhoneNumber);
        fd.append('MobileNumber', iData.MobileNumber);
        fd.append('FileImage', iData.FileImage);

        http.post(`${URLSVC}/Webs/UpdateProfile`,fd).then((res) => {
            loadingBar(false);
            let data = res.data;
            if (data.IsSuccess) {
                Swal.fire('Berhasil', `Anda telah berhasil memperbarui data`, 'success').then(function() {
                    window.location.replace('/')
                });
            } else {
                Swal.fire('Gagal', `${data.ReturnMessage}`, 'error')
            }
        })
    }
}
