const initialState = {
  dataPerubahanWilkerSurveyors: [],
  formPerubahanWilkerSurveyor: {
    TanggalLisensiBaru: null,
    FileDownloads: [],
    Catatan: "",
  },
  modalPerubahanWilkerSurveyor: false,
};

const pindahWilkerSbReducer = (state = initialState, action) => {
  switch (action.type) {
    case "DATA_PERUBAHAN_WILKER_SURVEYORS":
      return { ...state, dataPerubahanWilkerSurveyors: action.payload };
    case "FORM_PERUBAHAN_WILKER_SURVEYOR":
      return {
        ...state,
        formPerubahanWilkerSurveyor: {
          ...state.formPerubahanWilkerSurveyor,
          [action.formType]: action.formValue,
        },
      };
    case "RESET_FORM_PERUBAHAN_WILKER_SURVEYOR":
      return {
        ...state,
        formPerubahanWilkerSurveyor: initialState.formPerubahanWilkerSurveyor,
      };
    case "MODAL_PERUBAHAN_WILKER_SURVEYOR":
      return { ...state, modalPerubahanWilkerSurveyor: action.payload };
    default:
      return state;
  }
};

export default pindahWilkerSbReducer;
