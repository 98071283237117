import { http } from "src/config";
import { FUNCDateToString, FUNCNumberFormat } from "src/config/function";
import { URLSVC } from "src/config/helper/urlService";
import Swal from "sweetalert2";
import { loadingBar, setDataPermohonans } from "../globalAction";

export const setDataPengolahan = (idPermohonan) => {
  return (dispatch) => {
    loadingBar(true);
    dispatch({ type: "RESET_FORM_PENGOLAHAN" });
    http
      .get(
        `${URLSVC}/Pelayanans/GetPermohonanById?IdPermohonan=${idPermohonan}`
      )
      .then((res) => {
        loadingBar(false);
        let data = res.data;
        if (data.IsSuccess) {
          dispatch({ type: "MODAL_PENGOLAHAN", payload: true });
          dispatch(setFormPengolahan("IdPermohonan", data.Data.IdPermohonan));
          dispatch(setFormPengolahan("NoPermohonan", data.Data.NoPermohonan));
          dispatch(setFormPengolahan("Nama", data.Data.Nama));
          dispatch(setFormPengolahan("Email", data.Data.Email));
        } else {
          Swal.fire("Gagal", data.ReturnMessage, "error");
        }
      });
  };
};

export const setFormPengolahan = (formType, formValue) => {
  return { type: "FORM_PENGOLAHAN", formType, formValue };
};

export const apiProsesPengolahan = (iData) => {
  return (dispatch) => {
    loadingBar(true);
    var fd = new FormData();

    fd.append("IdPermohonan", iData.IdPermohonan);
    fd.append("HasilPengukuran", iData.HasilPengukuran);

    http.post(`${URLSVC}/Pelayanans/ProsesPengolahanData`, fd).then((res) => {
      loadingBar(false);
      let data = res.data;
      if (data.IsSuccess) {
        Swal.fire(
          "Berhasil",
          `Anda telah berhasil simpan data`,
          "success"
        ).then(function () {
          dispatch({ type: "MODAL_PENGOLAHAN", payload: false });
          dispatch(setDataPermohonans("DATA_PENGOLAHANS"));
        });
      } else {
        Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
      }
    });
  };
};
