const initialState = {
  dataHasilAkhirs: [],
  modalHasilAkhir: false,
  formHasilAkhir: {
    IdPermohonan: "",
    NoPermohonan: "",
    Nama: "",
    Email: "",
    JenisPermohonan: "",
    AlamatBidangTanah: "",
    JarakLokasiBidangTanah: "",
    LuasTanah: "",
    Latitude: "",
    Longitude: "",
    Wilker: {},
    Kjsb: "",
  },
  fileSyaratHasilAkhir: [],
};

const hasilAkhirReducer = (state = initialState, action) => {
  switch (action.type) {
    case "DATA_HASIL_AKHIRS":
      return { ...state, dataHasilAkhirs: action.payload };
    case "FORM_HASIL_AKHIR":
      return {
        ...state,
        formHasilAkhir: {
          ...state.formHasilAkhir,
          [action.formType]: action.formValue,
        },
      };
    case "RESET_FORM_HASIL_AKHIR":
      return {
        ...state,
        formHasilAkhir: initialState.formHasilAkhir,
      };
    case "MODAL_HASIL_AKHIR":
      return { ...state, modalHasilAkhir: action.payload };
    case "FILE_SYARAT_HASIL_AKHIR":
      return { ...state, fileSyaratHasilAkhir: action.payload };
    default:
      return state;
  }
};

export default hasilAkhirReducer;
